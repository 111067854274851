import PropTypes from "prop-types";
import { TableRow, TableCell } from "@mui/material";
import { format } from "date-fns";

// ----------------------------------------------------------------------

TransactionsTableRow.propTypes = {
  row: PropTypes.object,
};

export default function TransactionsTableRow({ row }) {
  const { transType, amount, description, transDate } = row;

  return (
    <TableRow hover>
      <TableCell align="left">{transType}</TableCell>

      <TableCell align="left">{amount}</TableCell>

      <TableCell align="left">{description}</TableCell>

      <TableCell align="left">
        {transDate && format(new Date(transDate), "dd-MM-yyyy")}
      </TableCell>
    </TableRow>
  );
}
