// react
import { createContext, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken } from "../utils/jwt";

// Initial State
const initialState = {
  orgs: null,
  loading: true,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { orgs } = action.payload;
    return {
      ...state,
      orgs,
      loading: false,
    };
  },
  CREATE_ORG: (state, action) => {
    const { org } = action.payload;
    return {
      ...state,
      orgs: [...state.orgs, org],
    };
  },
  UPDATE_ORG: (state, action) => {
    const { org } = action.payload;
    const updated = [...state.orgs];
    const toUpdate = updated.findIndex(
      (orgItem) => orgItem.org_id === org.org_id
    );
    updated[toUpdate] = org;
    return {
      ...state,
      orgs: updated,
    };
  },
  CHANGE_STATUS: (state, action) => {
    const { org } = action.payload;
    const updated = [...state.orgs];
    const toUpdate = updated.findIndex((orgItem) => orgItem.id === org.id);
    updated[toUpdate].user.status = org?.user?.status;
    return {
      ...state,
      resellers: updated,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const OrgListContext = createContext();

// Provider Component
export const OrgListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Auth state
  // const { isAuthenticated, isInitialized } = useAuth();

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get(
          "/api/org",
          { signal: controller.signal },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const { orgs } = await response.data;
        // cancel the request
        await controller.abort();
        dispatch({
          type: "INITIALIZE",
          payload: {
            orgs,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            orgs: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          orgs: null,
        },
      });
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const controller = new AbortController();
        if (accessToken && isValidToken(accessToken)) {
          const response = await axios.get(
            "/api/org",
            { signal: controller.signal },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const { orgs } = await response.data;
          // cancel the request
          await controller.abort();
          dispatch({
            type: "INITIALIZE",
            payload: {
              orgs,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              orgs: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            orgs: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const createOrg = async (orgData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("org_name", orgData.orgName);
      data.append("user_name", orgData.orgName);
      data.append("email", orgData.email);
      data.append("mobile", orgData.mobile);
      data.append("location", orgData.location);
      data.append("about", orgData.about);
      data.append("reseller_id", orgData.resellerID);
      data.append("password", "123456789");
      const response = await axios({
        method: "post",
        url: "/api/org",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { org } = await response.data;
      dispatch({
        type: "CREATE_ORG",
        payload: {
          org,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateOrg = async (orgData, orgID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("org_name", orgData.orgName);
      data.append("user_name", orgData.orgName);
      data.append("email", orgData.email);
      data.append("mobile", orgData.mobile);
      data.append("location", orgData.location);
      data.append("about", orgData.about);
      data.append("reseller_id", orgData.resellerID);
      data.append("password", "123456789");
      data.append("_method", "patch");
      const response = await axios({
        method: "post",
        url: `/api/org/${orgID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { org } = await response.data;
      dispatch({
        type: "UPDATE_ORG",
        payload: {
          org,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const changeStatus = async (orgID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/api/org_status/${orgID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { org } = await response.data;
      dispatch({
        type: "CHANGE_STATUS",
        payload: {
          org,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <OrgListContext.Provider
      value={{
        orgs: state.orgs,
        loading: state.loading,
        createOrg,
        changeStatus,
        updateOrg,
        initialize,
      }}
    >
      {children}
    </OrgListContext.Provider>
  );
};
