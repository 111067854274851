// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    home: path(ROOTS_DASHBOARD, "/home"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  organisation: {
    root: path(ROOTS_DASHBOARD, "/organisation"),
    new: path(ROOTS_DASHBOARD, "/organisation/new"),
    edit: (name) => path(ROOTS_DASHBOARD, `/organisation/${name}/edit`),
    crmMapping: path(ROOTS_DASHBOARD, "/organisation/crm-mapping"),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, "/reports"),
    new: path(ROOTS_DASHBOARD, "/reports/new"),
    edit: (name, id) => path(ROOTS_DASHBOARD, `/reports/${name}/${id}/edit`),
  },
  whatsapp: {
    root: path(ROOTS_DASHBOARD, "/whatsapp"),
    partnerRatesPnb: path(ROOTS_DASHBOARD,"/whatsapp/wallet/partner-rates-pnb"),
    transactions: path(ROOTS_DASHBOARD, "/whatsapp/wallet/transactions"),
  },
  reseller: {
    root: path(ROOTS_DASHBOARD, "/reseller"),
    new: path(ROOTS_DASHBOARD, "/reseller/new"),
    edit: (name) => path(ROOTS_DASHBOARD, `/reseller/${name}/edit`),
  },
  users: {
    root: path(ROOTS_DASHBOARD, "/users"),
    create: path(ROOTS_DASHBOARD, "/users/create-user"),
    list: path(ROOTS_DASHBOARD, "/users/users-list"),
    cards: path(ROOTS_DASHBOARD, "/users/cards"),
    profile: path(ROOTS_DASHBOARD, "/users/profile"),
    account: path(ROOTS_DASHBOARD, "/users/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/users/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/users/reece-chung/edit`),
  },
  company: {
    root: path(ROOTS_DASHBOARD, "/company"),
    new: path(ROOTS_DASHBOARD, "/company/new"),
    list: path(ROOTS_DASHBOARD, "/company/list"),
    cards: path(ROOTS_DASHBOARD, "/company/cards"),
    profile: path(ROOTS_DASHBOARD, "/company/profile"),
    account: path(ROOTS_DASHBOARD, "/company/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/company/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/company/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD,"/e-commerce/product/nike-blazer-low-77-vintage/edit"),
    demoView: path(ROOTS_DASHBOARD,"/e-commerce/product/nike-air-force-1-ndestrukt"),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD,"/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"),
    demoView: path(ROOTS_DASHBOARD,"/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD,"/blog/post/apply-these-7-secret-techniques-to-improve-event"),
  },
  googleWorkspace: {
    root: path(ROOTS_DASHBOARD, "/google-workspace"),
    workspaceCustomerInfo: path(ROOTS_DASHBOARD, "/google-workspace/info"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
