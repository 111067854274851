// react
import { useContext, useEffect, useState } from "react";
// hooks
import useSettings from "../../../hooks/useSettings";
// components
import Page from "../../../components/Page";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
import useTable, { getComparator, emptyRows } from "src/hooks/useTable";
// @mui
import {
  Button,
  Box,
  Stack,
  Card,
  Table,
  Switch,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
// components
import Scrollbar from "src/components/Scrollbar";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from "src/components/table";
import RatesDialog from "src/sections/@dashboard/whatsApp/wallet/partnerRatesPnb/RatesDialog";
import PatternTableRow from "src/sections/@dashboard/whatsApp/wallet/partnerRatesPnb/TabelRow";
import PatternTableToolbar from "src/sections/@dashboard/whatsApp/wallet/partnerRatesPnb/TableToolbar";
// context
import { WhatsAppWalletContext } from "src/contexts/whatsApp/wallet/WhatsAppWalletContext";
// Api's
import getSelfRates from "src/services/whatsapp/wallet/getSelfRates";
// ----------------------------------------------------------------------

const TEMPLATE_TYPES = ["all", "reseller"];

const TABLE_HEAD = [
  { id: "reseller_name", label: "Reseller Name", align: "left" },
  { id: "reseller_type", label: "Reseller Type", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "pnb", label: "PNB", align: "center" },
  { id: "rates", label: "", align: "right" },
];

// ----------------------------------------------------------------------

export default function PartnerRatesPnb() {
  const { themeStretch } = useSettings();

  const { initializePartnerRatesPnb, partnersPnb, loader } = useContext(
    WhatsAppWalletContext
  );

  useEffect(() => {
    initializePartnerRatesPnb();
  }, [initializePartnerRatesPnb]);

  const [rates, setRates] = useState({});

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [filterName, setFilterName] = useState("");
  const [filterRole, setFilterRole] = useState("all");

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const dataFiltered = applySortFilter({
    partnersPnb,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    dataFiltered &&
    !dataFiltered.length &&
    (filterName || filterRole !== "all");

  const [openRateDialog, setOpenRateDialog] = useState(false);

  const handleOpenRateDialog = () => {
    setOpenRateDialog(true);
  };

  const handleCloseRateDialog = () => {
    setOpenRateDialog(false);
  };

  useEffect(() => {
    const getSelfRateshandler = async () => {
      try {
        const response = await getSelfRates();
        const { rates } = response || {};
        setRates(rates);
      } catch (error) {
        console.log(error);
      }
    };
    getSelfRateshandler();
  }, []);

  return (
    <Page title="Reports">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading="Partner Rates / PNB"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            { name: "Whatsapp" },
            { name: "Wallet" },
            { name: "Partner Rates / PNB" },
          ]}
          action={
            <>
              <Button variant="contained" onClick={handleOpenRateDialog}>
                View Rates
              </Button>
              <RatesDialog
                viewOnly
                rates={rates}
                openRateDialog={openRateDialog}
                handleCloseRateDialog={handleCloseRateDialog}
              />
            </>
          }
        />
        <Card>
          <PatternTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={TEMPLATE_TYPES}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              {!loader ? (
                <Table size={dense ? "small" : "medium"}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={partnersPnb ? partnersPnb.length : 0}
                    onSort={onSort}
                  />

                  <TableBody>
                    {dataFiltered &&
                      dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <PatternTableRow key={index} row={row} />
                        ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(
                        page,
                        rowsPerPage,
                        partnersPnb && partnersPnb.length
                      )}
                    />

                    <TableNoData label="No Data" isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              ) : (
                <Stack
                  sx={{ width: "100%", height: "200px" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Stack>
              )}
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered ? dataFiltered.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ partnersPnb, comparator, filterName, filterRole }) {
  const stabilizedThis =
    partnersPnb && partnersPnb.map((el, index) => [el, index]);

  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

  partnersPnb = stabilizedThis && stabilizedThis.map((el) => el[0]);

  if (filterName) {
    partnersPnb =
      partnersPnb &&
      partnersPnb.filter(
        (item) =>
          item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      );
  }
  if (filterRole !== "all") {
    partnersPnb = partnersPnb.filter(
      (template) =>
        template.wallet.type.toLowerCase() === filterRole.toLowerCase()
    );
  }

  return partnersPnb;
}
