import PropTypes from "prop-types";
import { TableRow, TableCell, Typography, Button } from "@mui/material";
import RatesDialog from "./RatesDialog";
import { useState } from "react";
import getRates from "src/services/whatsapp/wallet/getRates";

// ----------------------------------------------------------------------

PartnersPnbTableRow.propTypes = {
  row: PropTypes.object,
};

export default function PartnersPnbTableRow({ row }) {
  const { name, wallet } = row || {};
  const { type, typeId, status = "Active", pnb } = wallet || {};

  const [openRateDialog, setOpenRateDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [rates, setRates] = useState({});

  const handleOpenRateDialog = async () => {
    try {
      setIsLoading(true);
      setOpenRateDialog(true);
      const data = await getRates(type, typeId);
      const { rates } = data || {};
      console.log(data);
      setRates(rates);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseRateDialog = () => {
    setOpenRateDialog(false);
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </TableCell>

        <TableCell align="left">{type}</TableCell>

        <TableCell align="left">{status}</TableCell>

        <TableCell align="center">{pnb}</TableCell>

        <TableCell align="right">
          <Button
            variant="outlined"
            onClick={() => {
              handleOpenRateDialog();
            }}
          >
            Rates
          </Button>
        </TableCell>
      </TableRow>
      <RatesDialog
        rates={rates}
        type={type}
        typeId={typeId}
        isLoading={isLoading}
        openRateDialog={openRateDialog}
        handleCloseRateDialog={handleCloseRateDialog}
      />
    </>
  );
}
