// react
import { createContext, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken } from "../utils/jwt";

// Initial State
const initialState = {
  resellers: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { resellers } = action.payload;
    return {
      ...state,
      resellers,
    };
  },
  CREATE_RESELLER: (state, action) => {
    const { reseller } = action.payload;
    return {
      ...state,
      resellers: [...state.resellers, reseller],
    };
  },
  UPDATE_RESELLER: (state, action) => {
    const { reseller } = action.payload;
    const updated = [...state.resellers];
    const toUpdate = updated.findIndex(
      (resellerItem) => resellerItem.reseller_id === reseller.reseller_id
    );
    updated[toUpdate] = reseller;
    return {
      ...state,
      resellers: updated,
    };
  },
  CHANGE_STATUS: (state, action) => {
    const { reseller } = action.payload;
    const updated = [...state.resellers];
    const toUpdate = updated.findIndex(
      (resellerItem) => resellerItem.reseller_id === reseller.reseller_id
    );
    updated[toUpdate].user.status = reseller.user.status;
    return {
      ...state,
      resellers: updated,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const ResellerContext = createContext();

// Provider Component
export const ResellerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Auth state
  // const { isAuthenticated, isInitialized } = useAuth();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const controller = new AbortController();
        if (accessToken && isValidToken(accessToken)) {
          const response = await axios.get(
            "/api/reseller",
            { signal: controller.signal },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const { resellers } = await response.data;
          // cancel the request
          controller.abort();
          dispatch({
            type: "INITIALIZE",
            payload: {
              resellers,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              resellers: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            resellers: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const createReseller = async (resellerData) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      data.append("logo", resellerData.resellerLogo);
      data.append("landing_image", resellerData.resellerLandingImg);
      data.append("reseller_name", resellerData.name);
      data.append("user_name", resellerData.name);
      data.append("tagline", resellerData.tagline);
      data.append("email", resellerData.email);
      data.append("mobile", resellerData.mobile);
      data.append("location", resellerData.location);
      data.append("reseller_type", resellerData.type);
      data.append("reseller_url", resellerData.url);
      data.append("about", resellerData.about);
      data.append("password", "123456789");
      const response = await axios({
        method: "post",
        url: "/api/reseller",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { reseller } = await response.data;
      dispatch({
        type: "CREATE_RESELLER",
        payload: {
          reseller,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateReseller = async (resellerData, resellerID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const data = new FormData();
      typeof resellerData.resellerLogo === "object" &&
        data.append("logo", resellerData.resellerLogo);
      typeof resellerData.resellerLandingImg === "object" &&
        data.append("landing_image", resellerData.resellerLandingImg);
      data.append("reseller_name", resellerData.name);
      data.append("user_name", resellerData.name);
      data.append("tagline", resellerData.tagline);
      data.append("email", resellerData.email);
      data.append("mobile", resellerData.mobile);
      data.append("location", resellerData.location);
      data.append("reseller_type", resellerData.type);
      data.append("reseller_url", resellerData.url);
      data.append("about", resellerData.about);
      data.append("password", "123456789");
      data.append("_method", "patch");
      const response = await axios({
        method: "post",
        url: `/api/reseller/${resellerID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { reseller } = await response.data;
      dispatch({
        type: "UPDATE_RESELLER",
        payload: {
          reseller,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const changeStatus = async (resellerID) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/api/reseller_status/${resellerID}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const { reseller } = await response.data;
      dispatch({
        type: "CHANGE_STATUS",
        payload: {
          reseller,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <ResellerContext.Provider
      value={{
        resellers: state.resellers,
        createReseller,
        updateReseller,
        changeStatus,
      }}
    >
      {children}
    </ResellerContext.Provider>
  );
};
