// react
import { useContext, useEffect, useState } from "react";
// @mui
import {
  Box,
  Stack,
  Card,
  Table,
  Switch,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
// components
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from "src/components/table";
import Page from "../../../components/Page";
import Scrollbar from "src/components/Scrollbar";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// hooks
import useTable, { getComparator, emptyRows } from "src/hooks/useTable";
import useSettings from "../../../hooks/useSettings";

// sections
import {
  TableToolbar,
  TableRow,
} from "src/sections/@dashboard/whatsApp/wallet/transactions";
// context
import { WhatsAppWalletContext } from "src/contexts/whatsApp/wallet/WhatsAppWalletContext";

// ------------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "transType", label: "Transaction Type", align: "left" },
  { id: "amount", label: "Amount", align: "left" },
  { id: "description", label: "Description", align: "left" },
  { id: "date", label: "Date", align: "left" },
];

const FILTER_TYPES = ["all", "debit", "credit"];

// ----------------------------------------------------------------------

export default function Transactions() {
  const { themeStretch } = useSettings();

  const {initializeTransactions, transactions, loader } =
    useContext(WhatsAppWalletContext);

  useEffect(() => {
    initializeTransactions();
  }, [initializeTransactions]);

  const { history } = transactions || {};
  
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [filterName, setFilterName] = useState("");
  const [filterRole, setFilterRole] = useState("all");

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const dataFiltered = applySortFilter({
    history,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    dataFiltered &&
    !dataFiltered.length &&
    (filterName || filterRole !== "all");

  return (
    <Page title="Transactions">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading="Transactions"
          links={[
            { name: "Dashboard", href: PATH_DASHBOARD.root },
            { name: "WhatsApp" },
            { name: "Wallet" },
            { name: "Transactions" },
          ]}
        />
        <Card>
          <TableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={FILTER_TYPES}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: "relative" }}>
              {!loader ? (
                <Table size={dense ? "small" : "medium"}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={history ? history.length : 0}
                    onSort={onSort}
                  />

                  <TableBody>
                    {dataFiltered &&
                      dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow key={index} row={row} />
                        ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(
                        page,
                        rowsPerPage,
                        history && history.length
                      )}
                    />

                    <TableNoData
                      label="No Transactions"
                      isNotFound={isNotFound}
                    />
                  </TableBody>
                </Table>
              ) : (
                <Stack
                  sx={{ width: "100%", height: "200px" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Stack>
              )}
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: "relative" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered ? dataFiltered.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ history, comparator, filterName, filterRole }) {
  const stabilizedThis = history && history.map((el, index) => [el, index]);

  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

  history = stabilizedThis && stabilizedThis.map((el) => el[0]);

  if (filterName) {
    history =
      history &&
      history.filter(
        (item) =>
          item.transType.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1
      );
  }

  if (filterRole !== "all") {
    history = history.filter(
      (template) =>
        template?.transType?.toLowerCase() === filterRole.toLowerCase()
    );
  }

  return history;
}
