// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";
import { HOST_API_WHATSAPP_WALLET } from "src/config";

export default async function getRates(type, typeId) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `${HOST_API_WHATSAPP_WALLET}/whatsapp/rates/get`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          type: type,
          typeId: typeId,
        },
      });
      const res = await response.data;
      console.log(res);
      return res;
    }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
